
function goToProductDetailCorner(brndNm, godNo, dspCtgryNo, flag, dpos, setSn) {
  var utag = '';
  var ref_myact = $('input[name="ref_myact"]').val();
  if (flag == 'recommendation') {
    utag = $('#' + flag).attr('utag') + "$tp:cl$lid:" + recoLabId + "$mid:" + recoMethodId;
  } else {
    utag = $('#' + flag).attr('utag');
    if (flag != 'SALE' && flag != "recoBrnd") {
      var setUtag = $('#' + flag).find("li[aria-selected=true] a").text();
      if (setUtag != '' && setUtag.length > 0) {
        utag += '$set:' + encodeURIComponent(setUtag);
      }
    }
  }

  if(typeof(setSn) != "undefined" && setSn != ""){
      utag += "$set:" + setSn;
  }

  if(typeof(dpos) != "undefined" && dpos != ""){
      utag += "$dpos:" + dpos;
  }

  if(ref_myact != "undefined" && ref_myact != null && ref_myact != ''){
      utag += '$ref_myact:' + ref_myact;
  }
  if((typeof givenMain) != "undefined" && (typeof givenMain.main_imid)!="undefined" && givenMain.main_imid != "" ) {
  	utag += '$imid:' + givenMain.main_imid;
  }
  var url = "/" + brndNm + "/" + godNo + "/good?" + utag + "&dspCtgryNo=" + dspCtgryNo + "&brandShopNo=" + $("#brandShopNo").val() + "&brndShopId=" + $("#lnbBrndShopId").val() + "&leftBrandNM=" + $("#leftBrandNM").val();
  location.href = url;
}

function goToProductActionLogging(flag, logTagPage, cnr, set, dpos, godTargetUrl){
    var tp = cnr;
    logTagPage = $("input[name='logTagPage']").val();
    if(tp == ''){
        var $utag = $('#' + flag);
        if( typeof($utag) != "undefined" && typeof($utag.attr('utag')) != "undefined" && $utag.attr('utag').length > 0){
            // #185353 tp에 코너번호 외 $ref_br 값 제외 요청
            if($utag.attr('utag').indexOf('ref_br') > -1){
                tp = $utag.attr('utag').substring($utag.attr('utag').indexOf("ref_cnr")+8,$utag.attr('utag').indexOf("ref_br")-1);
            }else{
                tp = $utag.attr('utag').substring($utag.attr('utag').indexOf("ref_cnr")+8);
            }
        }
    }

    var logSet = set;
    if(logSet == '') {
        var setUtag = $('#' + flag).find("li[aria-selected=true] a").text();
        if (typeof(setUtag) != 'undefined' && setUtag != '' && setUtag.length > 0) {
            set = encodeURIComponent(setUtag);
        }
    }

    action_logging({'tagPage':logTagPage,'tagArea':'cornner','tp':tp,'set':set,'dpos':dpos,'target':godTargetUrl});
}

function topSellerLoading(brndId, lang, dspCtgryNo, imgTurn, type) {
  var line = ''
  var ctgryTp = '';
  var upperDspCtgryNo = '';
  var arr = [];
  var isFilter = false;
  if (type == 'TOP_AType' || type == 'TOP_EType') {
    line = 3;
  } else if (type == 'TOP_CType') {
    line = 5;
  } else if (type == 'TOP_BType') {
    line = 9;
  } else if (type == 'TOP_CTGType') {
    line = 5;
    ctgryTp = 'CTGRY';
	  if(dspCtgryNo === '' || dspCtgryNo === undefined || dspCtgryNo === null){ // 전체 탭일 경우
        isFilter = false;
     }else{
        isFilter = true;
     }
    var sortLi = $("#mainTopCategory" + type + " li");
    upperDspCtgryNo = $(sortLi[0]).attr('upperDspCtgryNo');
	sortLi.each(function(index){
		if($(this).attr('ctgryno') != null){
			arr.push($(this).attr('ctgryno'));
		}
	});
  } else {
	line = 7; /* #91196 DType 일경우 최대 8개까지 노출 */
  }

  $.ajax({
    type: "GET",
    url: "/topSellerCorner",
    data: { "brndId": brndId, "dspCtgryNo": dspCtgryNo, "imgTurn": imgTurn, "line": line,"ctgrySectCd": givenMain.dspCtgryInfoDspCtgryCtgrySectCd, "dscr": "topSeller" + type
		, "ctgryTp": ctgryTp, "ctgryNoList": arr.toString(), "upperDspCtgryNo": upperDspCtgryNo, "isFilter" : isFilter},
    async: true,
    success: function (data) {
      if (type == 'TOP_AType') {
        drawTopSellerAType(data, lang, type);
      } else if (type == 'TOP_CType') {
        drawTopSellerCType(data, lang, type);
      } else if (type == 'TOP_BType'){
        drawTopSellerBType(data, lang, type);
      } else if (type == 'TOP_CTGType') {
      	drawTopSellerCTGType(data, lang, type);
      } else {
    	drawTopSellerEType(data, lang, type);
      }

      lazyLoad.init();
    }
  });
}

function newInLoading(brndId, lang, dspCtgryNo, imgTurn, type, isFilter) {
    let ctgryNoList = $("#newIndspCtgryList"+type).val() || '';

    if(ctgryNoList == ''){ //bo에 세팅된 카테고리 없으면 코너 미노출
        $("#newIn"+type).remove();
         return;
    }else {
        $.ajax({
            type: "GET",
            url: "/getCornerGodCnt.json",
            data: {
                  "brndId": brndId,
                  "ctgryNoList": ctgryNoList
                },
            async: true,
            success: function (data) {
                let ctgryNoArr = ctgryNoList.replace(/,\s*$/, "").split(",");
                let newInCtgryArr ="";
                if (data.godExistCtgry.length < 1) { //상품이 존재하는 카테고리가 없으면 코너미노출.
                    $("#newIn"+type).remove();
                    return;
                }

                for (let ctgryNo of ctgryNoArr) {
                    if ($.inArray(ctgryNo, data.godExistCtgry) < 0) {
                        $("#mainNewCategory"+type).find("li[ctgryno=" + ctgryNo + "]").remove();
                    }else if($.inArray(ctgryNo, data.godExistCtgry) >= 0){
                        if($("#newIndspCtgryListNEW_CTGType").length>0){
                            newInCtgryArr += ctgryNo+",";
                        }
                    }
                }

                if(newInCtgryArr != "" && newInCtgryArr!= null){
                    $("#newIndspCtgryListNEW_CTGType").val(newInCtgryArr);
                }

                let selectedNewInLi = $("#mainNewCategory"+type).find("li").get(randomArrayIndex($("#mainNewCategory"+type).find("li").length));
                if(!selectedNewInLi ){
                    selectedNewInLi = $("#mainNewCategory"+type).find("li").eq(0);
                }

                $("#mainNewCategory"+type).find("li").eq(0).click();
            }
        });
    }
}

function drawNewInCorner(brndId, lang, dspCtgryNo, imgTurn, type, isFilter){
    var productWall = $('#newin-godList' + dspCtgryNo);

    if(productWall.length > 0){
        $('#newin-godList' + dspCtgryNo).attr('style','display: display;').siblings(".godList").attr('style','display: none;');
        $("#newin_"+dspCtgryNo).attr("aria-selected","true").siblings().attr("aria-selected","false");
    }else{
        $.ajax({
           type: "GET",
           url: "/newInCorner",
           data: {
             "brndId": brndId,
             "dspCtgryNo": dspCtgryNo,
             "imgTurn": imgTurn,
             isFilter: isFilter,
             "line": 5,
             "ctgrySectCd": givenMain.dspCtgryInfoDspCtgryCtgrySectCd,
             "dscr": "newIn" + type
           },
           async: true,
           success: function (data) {
               var html = "";
               html += "<ul class='godList column3' id='newin-godList"+dspCtgryNo+"'>"
               html += "<input type='hidden' name='newinLoadYn' value='Y' />"
               html += "<h4 class='wa_hidden'>"+ givenMain.mallmain_corner_newIn_title_all +"</h4>";
               html += data;
               html += "</ul>";

               $('#newIn' + type).append(html);
               $('#newin-godList' + dspCtgryNo).attr('style','display: display;').siblings(".godList").attr('style','display: none;');
               $("#newin_"+dspCtgryNo).attr("aria-selected","true").siblings().attr("aria-selected","false");

               lazyLoad.init();
           }
        });
    }
}

//대카메인 topseller
var topIndex = 0;
function drawTopSellerCTGType(data, lang, type){
	var html = "";
	var newCtgry = "";
	var selectedLi = "";
	var headerNm = $("#mainTopCategory" + type + " li.selected").find("a").text();
	var productWall = $('#topSeller' + type + ' .godList:eq(' + topIndex + ')');
	var topsellerLoadYn = productWall.find("input[name='topsellerLoadYn']");

	if(productWall.length > 0){

		if(topsellerLoadYn.val() == 'N') {
            html += "<h4 class='wa_hidden'>" + headerNm + "</h4>";
            html += data;
        }

        productWall.siblings("ul").css("display","none");
        productWall.css("display","");
        productWall.append(html);
        topsellerLoadYn.val('Y');
	}else{
		$('#topSeller' + type + ' .godList').remove();
        html += "<ul class='godList column3'>"
		html += "<input type='hidden' name='topsellerLoadYn' value='Y' />"
		html += "<h4 class='wa_hidden'>"+ givenMain.mallmain_corner_topSeller_title_all +"</h4>";
		html += data;
		html += "</ul>";

		$('#topSeller' + type).append(html);

		if (topSellerCnrGodCnt < 1) {
			$("#topSeller" + type).remove();
		} else {
			//해당 카테고리의 상품개수 10개 이하인 탭 삭제
			checkGodCntCtgry($('#mainTopCategory'+type+' li'),topSellerCheckCntCtgryNo);
			newCtgry = $('#mainTopCategory'+type+' li');
			if($(newCtgry).length >= 2){
				newInCtgryCheck(newCtgry); //탭 카테고리 최대 10개 노출

				var html2 = "";
				for(var i=1; i<newCtgry.length; i++){
                    html2 += "<ul class='godList column3'>"
					html2 += "<input type='hidden' name='topsellerLoadYn' value='N' />";
                    html2 += "</ul>"
				}
				$('#topSeller' + type).append(html2);

				$("a", newCtgry).on("click", function(e){
                    var index = $(this).parent().index();
                    topIndex = index;
                    var dspCtgryNo = '';
                    selectedLi = newCtgry.eq(index);
                    if (selectedLi.attr('id') != "" && selectedLi.attr('id') != undefined) {
                        dspCtgryNo = $(selectedLi).attr('ctgryNo'); // 전체가 아니면 해당 대카테고리 번호 셋팅
                    }
                    productWall = $("#topSeller" + type + " .godList:eq("+topIndex+")");
                    topsellerLoadYn = productWall.find("input[name='topsellerLoadYn']");

                    if(topsellerLoadYn.val() === 'N'){
                        topSellerLoading(givenMain.brndId, lang, dspCtgryNo, $("#topSeller" + type).attr("imgTurn"), type);
                    }
                    else{
                        productWall.siblings("ul").css("display","none");
                        productWall.css("display","");
                    }
                    selectedLi.attr("aria-selected","true");
                    selectedLi.siblings().attr("aria-selected","false");
					e.preventDefault();
				});

			}else{
				$("#topSeller" + type + " .main-category").remove();
			}

		}
	}
}

// #101626 카테고리를 10개까지 노출
function newInCtgryCheck(newCaterory){
	if($(newCaterory).length > 10){ // 최대 10개 노출
		var delCnt = $(newCaterory).length - 10;
		for(var i = 0; i < delCnt; i++){
			$(newCaterory).get(10).remove();
		}
	}
}

let lagSpcltyYn = 'N';
if(givenMain.sectionNo != '' //&& givenMain.sectionNo != 'THMA33A03'
    && $("#dspCtgryNo").val() != ''
    && $("#lnbBrndShopId").val() == ''){
    lagSpcltyYn = 'Y';// 대카, 전문관일 경우 ALL이 설정되므로 cnncNo = ''로 주기 위해 설정
}

function randomArrayIndex(listCnt){
    let end = listCnt;
    let start = 1;
    let result = Math.floor((Math.random() * (end-start+1)) + start);
    return result - 1 < 0 ? 0 : result-1;
}

function getRankingParam(type, cnncCtgryNo){
    let otltYn = givenMain.sectionNo === 'THMA33A03' ? "Y" : "";
    let rankSect = type.indexOf("CLICK_RANK") > -1? "CLICK_RANK" : type.indexOf("SALE_RANK") > -1? "SALE_RANK" : "8S_RANK";
    let upperDspCtgryNo = lagSpcltyYn === 'Y' || otltYn == "Y" ? $("#dspCtgryNo").val() : "";
    let ctgryFlterCd = $("#ctgryFlterCd"+type).val() || "CTGRY_ALL"; //카테고리세트 비고란에 입력된 카테고리필터값 (없으면 CTGRY_ALL)
    let dspCtgryNo = lagSpcltyYn === 'Y' || otltYn == "Y" ? $("#dspCtgryNo").val() : "";
    
    if(otltYn == "Y"){
        ctgryFlterCd = "CTGRY_OTLT";
    }
    if(type === '8S_RANK') {
        ctgryFlterCd = $("#best-ranking-nav").find("[ctgryno='"+cnncCtgryNo+"']").attr("ctgryfltercd");
    }

    let pageSectCd = (type === '8S_RANK')? "MAIN" : "CTGRY";
    let cnrSn = $("#rankingCnrSn"+type).val();
    let cnrSetNm = $("#ranking-nav"+type).find("li[ctgryno='"+cnncCtgryNo+"']").find("a").text();

    let rankingParams = {
        "pageSectCd" : pageSectCd,
        "brndShopId" : givenMain.brndId,
        "brandShopNo" : givenMain.brandShopNo,
        "otltYn" : otltYn,
        "dspCtgryNo" : dspCtgryNo,
        "cnncCtgryNo" : cnncCtgryNo, //선택한 dspCtgryNo
        "ctgryNoList" : $("#dspCtgryList"+type).val(),
        "upperDspCtgryNo" : upperDspCtgryNo,
        "dspCnrDscr" : type,
        "ctgryFlterCd" : ctgryFlterCd,
        "rankSect" : rankSect,
        "preferAgeCd" : "ALL",
        "lagSpcltyYn" : lagSpcltyYn,
        "cnrSn" : cnrSn,
        "cnrSetNm" : cnrSetNm
    }

    return rankingParams;
}

/**
 * 랭킹코너 최초 로딩 시 상품개수 부족한 카테고리 제거 및 상품목록 조회
 * bo에 세팅된 카테고리 중 노출할 상품 6개이상 존재하는 카테고리 조회
 */
function rankingLoading(type){
    let $rankingNav = $("#ranking-nav"+type);
    let $rankingSection = $("#ranking"+type);
    let ctgryNoList = $("#dspCtgryList"+type).val();

    if(ctgryNoList == ''){ //bo에 세팅된 카테고리 없으면 카테고리영역 제거 후 전체로 조회 (카테고리세트 비고란에 입력된 카테고리필터값)
        $rankingNav.parent().remove();
        getRankingGodList(type, "", "Y");

        return;
    }else {
        $.ajax({
            type: "GET",
            url: "/getRankingCornerCtgry.json",
            data: getRankingParam(type, ""),
            async: true,
            success: function (data) {

                if (data.godExistCtgry.length < 1) { //상품이 존재하는 카테고리가 없으면 코너미노출
                    $rankingSection.remove();
                    return;
                }

                let ctgryNoArr = ctgryNoList.replace(/,\s*$/, "").split(",");
                for (let ctgryNo of ctgryNoArr) {
                    if ($.inArray(ctgryNo, data.godExistCtgry) < 0) {
                        $rankingNav.find("li[ctgryno=" + ctgryNo + "]").remove();
                    }
                }

                let selectedRankingLi = $rankingNav.find("li").get(randomArrayIndex($rankingNav.find("li").length));
                if(!selectedRankingLi ){
                    selectedRankingLi = $rankingNav.find("li").eq(0);
                }

                set_scrollX_po();
            }
        });
    }
}

/**
 * 랭킹코너 카테고리 별 상품조회
 * 한번 조회된 카테고리는 display처리
 */
function getRankingGodList(type, cnncCtgryNo, firstTab){
    let $rankingNav = $("#ranking-nav"+type);
    let $rankingSection = $("#ranking"+type);

    $rankingNav.find("li[ctgryno='"+cnncCtgryNo+"']").attr("aria-selected",true).siblings().attr("aria-selected",false);

    if($("#ranking-godList"+type+cnncCtgryNo).length > 0){
        $("#ranking-godList"+type+cnncCtgryNo).attr('style','display: display;').siblings(".godList").attr('style','display: none;');
    }else{

       $.ajax({
          type: "GET",
          url: "/rankingCorner",
          data: getRankingParam(type, cnncCtgryNo),
          async: true,
          success: function (data) {
            if(data.indexOf("li view-godno") < 0 && firstTab==='Y'){ //최초 로딩인데 상품 없으면 코너 미노출
                $rankingSection.remove();
                return;
            }

            $rankingSection.find("ul.godList").attr('style','display: none;');

            let html = "<ul class='godList column3' id='ranking-godList" + type + cnncCtgryNo + "'>";
            html += data;
            html += "</ul>";
            $rankingSection.append(html);

            if(firstTab === 'Y' && $("#ranking-godList"+type+cnncCtgryNo).find("li").length < 6){ //최초 로딩인데 상품수 부족하면 코너 미노출
                $rankingSection.remove();
                return;
            }

            lazyLoad.init();
          }

      });
    }
}

function rankingViewMore(type){
    let cnncCtgryNo = type ===  "8S_RANK" ? $("#best-ranking-nav").find("li[aria-selected='true']").attr("ctgryno") || "" : $("#ranking-nav"+type).find("li[aria-selected='true']").attr("ctgryno") || "" ;
    let rankingparams = getRankingParam(type,cnncCtgryNo);
    let upperCtgryNo = type ===  "8S_RANK" ? "" : rankingparams.upperDspCtgryNo || "SFMA41" ;
    let url = type ===  "8S_RANK" ? "/bestRankingViewMore" : "/ranking";
    let brndShopId = givenMain.brandShopNo ? givenMain.brndId : "";
    
    //utag 작업 추가
    var utag = type ===  "8S_RANK" ? $("#"+type).attr('utag') : $("#ranking"+type).attr('utag');
    url = url + "?"+ utag +"&rankSect="+rankingparams.rankSect + "&ctgryFlterCd="+rankingparams.ctgryFlterCd+"&brndShopId="+brndShopId+"&brandShopNo="+givenMain.brandShopNo+"&dspCtgryNo="+rankingparams.dspCtgryNo+"&cnncCtgryNo="+upperCtgryNo+"&otltYn="+rankingparams.otltYn;
    
    //클릭로깅 작업 추가
    var logTagPage = $("input[name='logTagPage']").val();
    var cnrSn;
    if(utag.indexOf("ref_cnr")>-1){
        cnrSn = utag.substring(utag.indexOf("ref_cnr")+8);
    }
    action_logging({'tagPage':logTagPage,'tagArea':'cornner','tp':cnrSn,'target':url});

    location.href = url;

}

function checkGodCntCtgry(ctgryTap,cntCtgryNo){
	var cntCtgryNoList = cntCtgryNo.split(',');
	for(var k=1; k<ctgryTap.length; k++){
		if($.inArray($(ctgryTap[k]).attr('ctgryno'),cntCtgryNoList) < 0){
			ctgryTap[k].parentNode.removeChild(ctgryTap[k]);
		}
	}
}